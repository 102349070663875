$(document).ready(function () {
    if (document.getElementById('cookie') !== null) {
        document.getElementById('cookie').addEventListener('click', function () {
            let d = new Date();
            d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000));
            let expires = "expires=" + d.toUTCString();
            document.cookie = 'cookie-settings' + "=" + 'accepted' + ";" + expires + ";path=/;";
            document.getElementsByClassName('CookiePopUp')[0].style.opacity = 0;
            document.getElementsByClassName('CookiePopUp')[0].style.visibility = 'hidden';
        });
    }

    $('#no-cookie').click(function () {
        let d = new Date();
        let expires = "expires=" + d.toUTCString();
        document.cookie = 'cookie-settings' + "=" + 'accepted' + ";" + expires + ";path=/;";
        document.getElementsByClassName('CookiePopUp')[0].style.opacity = 0;
        document.getElementsByClassName('CookiePopUp')[0].style.visibility = 'hidden';
    });
});
