var toastr = require("toastr");


var is_aborted_call = false;
var is_loading = false;
var browser_data = {
    browser_data: {}
};

function update_send_button() {
    var email = $("#online-check").val();
    var valid_email = 0;
    $("#submit").attr("disabled", "disabled").addClass("disabled");
    if (validate_email(email)) {
        $("#submit").removeAttr("disabled").removeClass("disabled");
        valid_email = 1;
    } else {
        valid_email = 0;
    }
    if ($("#send-me-tips-checkbox").is(":checked")) {
        $("#scanme-alert").hide();
    }

    return valid_email;
}

var rapid_scan_progress = 0;

/**
 * Rapid scan always takes at least 5 seconds
 */
function start_scan() {
    if (!is_aborted_call) {
        $("#rapid-scan-modal").show().addClass('animated fadeInUp');
        jQuery(window).trigger('resize').trigger('scroll');
    }
    if (rapid_scan_progress <= 4) {
        $("[data-scan-load]").hide(0);
        switch (rapid_scan_progress) {
            case 0:
                set_progress(0);
                $("[data-scan-load=25]").show();
                $(".quick-loading-label").addClass("quick-loading-label_25");
                $(".quick-loading").addClass("quick-loading_25");
                break;
            case 1:
                set_progress(25);
                $("[data-scan-load=50]").show();
                $(".quick-loading-label").addClass("quick-loading-label_50");
                $(".quick-loading").addClass("quick-loading_50");
                break;
            case 2:
                set_progress(50);
                $("[data-scan-load=75]").show();
                $(".quick-loading-label").addClass("quick-loading-label_75");
                $(".quick-loading").addClass("quick-loading_75");
                break;
            case 3:
                set_progress(75);
                $("[data-scan-load=100]").show();
                $(".quick-loading-label").addClass("quick-loading-label_100");
                $(".quick-loading").addClass("quick-loading_75");
                break;
            case 4:
                // set_progress(100);
                $("[data-scan-load=100]").show(0);

                // $(".quick-loading-label").addClass("quick-loading-label_100");
                // $(".quick-loading").addClass("quick-loading_75");
                break;
        }
        rapid_scan_progress++;
        setTimeout(function () {
            console.log('start scan');
            start_scan();
        }, 1250);
    }
}

function reset_scan() {
    set_progress(0);
    $("#send-me-tips-alert").hide();
    $("[data-response-show]").hide();
    rapid_scan_progress = 0;
    $(".quick-loading-label").removeClass("quick-loading-label_25")
        .removeClass("quick-loading-label_50")
        .removeClass("quick-loading-label_75")
        .removeClass("quick-loading-label_100");
    $(".quick-loading").removeClass("quick-loading_25")
        .removeClass("quick-loading_50")
        .removeClass("quick-loading_75")
        .removeClass("quick-loading_100");
    $("[data-scan-load]").hide(0);
    $("[data-results]").hide(0);
    $("[data-results-class]").removeClass();
    $("[data-scan-load=25]").show(0);
}

function set_progress(progress) {
    $('.risk-progress-circle').attr('data-progress', progress);
}

function rapid_scan_api(modal) {
    if (is_aborted_call) {
        setTimeout(function () {
            rapid_scan_api(modal);
        }, 500);
        return false;
    }

    var email = $(".scan_email").val();

    //var homepage_id = $('.free-scan-button').attr('data-homepage-id');
    var sendMeTipsCheckbox = $("#send-me-tips-checkbox");

    setTimeout(function () {
        $("html, body").animate({scrollTop: $('#rapid-scan-modal').offset().top - 200}, 750);
    }, 500);

    if (validate_email(email)) {
        if (modal != '') {
            reset_scan();
            start_scan();
        }
        browser_data._token = window.APP.csrf_token;
        browser_data.email = $('.scan_email').val();

        $("[data-request='email']").val(email);
        $("[data-request-text='email']").html(email);

        if (! is_loading) {
            $.ajax({
                url: '/api/v1/scan/email',
                method: 'POST',
                data: browser_data,
                timeout: 120000,
                beforeSend: function () {
                    is_aborted_call = false;
                    is_loading = true;
                    $("[data-step]").hide();
                    $("[data-step='load']").show();
                    $("[data-hide-after='start']").hide();
                },
                error: function (request, error) {
                    if (is_aborted_call == false) {
                        $("[data-step]").hide();
                        $("[data-step='api-error']").show();
                    }
                },
                success: function (data) {
                    $("[data-scan-load=100]").show(0);

                    if (is_aborted_call) {
                        is_aborted_call = false;
                        return false;
                    }

                    set_progress(100);
                    setTimeout(function () {

                        $("#rapid-scan-modal").removeClass("animted fadeInUp").show().addClass('animated fadeInUp');

                        jQuery(window).trigger('resize').trigger('scroll');

                        $("#checkbox_use_email").click();
                        $("[data-step]").hide();
                        $("[data-step='after-load']").show();

                        $("[data-step='step'][data-value='" + ((data.is_safe)?'success':'warning') + "']").show();
                        if(data.is_safe === false){
                            $('.breach_contains').text(data.breach_contains);
                        }
                        $("[data-response-show]").show();

                        if (data.is_safe === true || data.is_safe === false) {
                            $("[data-set-url='report']").attr("href", "/api/send-me-my-report/" + data.scan_report);

                            setTimeout(function () {
                                jQuery(window).trigger('resize').trigger('scroll');
                            }, 350);
                            setTimeout(function () {
                                $("html, body").animate({scrollTop: $('#rapid-scan-anchor').offset().top - 150}, 750);
                            }, 1150);

                        }
                    }, 600);

                },
                complete: function () {
                    is_loading = false;
                }
            });
        }

    } else {
        toastr.error('Please enter a valid email address', '');

        console.log('Please enter valid e-mail address');
        //showNotify("Please enter valid e-mail address", 'error');
    }
    $("#scanme-alert").hide();
}

function validate_email(email) {
    var rgx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return rgx.test(email);
    //return true;
}

(function ($) {
    $(document).ready(function () {

        $('.email-report-link').click(function (e) {
            e.preventDefault();
            var data = {
                email: $('input[type=email]').val(),
                domain: $('.scan_domain').val()
            };


            $.ajax({
                url: $('.email-report-link').attr('href'),
                method: 'GET',
                data: data,
                timeout: 120000,
                beforeSend: function () {

                },
                error: function (request, error) {
                    console.log(error);
                },
                success: function (data) {
                    if (data.message === 'success') {
                        $('#rapid-scan-modal').hide();
                        $('#report-sent-success').show();
                        $("html, body").animate({scrollTop: $('#report-sent-success').offset().top - 200}, 750);
                        gtag('event', 'Send', {
                            'event_category': 'Reports',
                            'event_label': 'Home',
                            'value': ''
                        });
                    }
                }
            });
        });

        $("input[name=send-me-tips-checkbox]").click(function () {
            var newsletter = $("#send-me-tips-checkbox-1").is(":checked") ? '1' : '0';
            // update url
            $("[data-set-url='report']").attr("href", $("[data-set-url='report']").attr("href") + "?newsletter=" + newsletter);
        });

        $(".free-scan-cancel").click(function () {
            is_aborted_call = true;
            rapid_scan_progress = 5;
            $("#rapid-scan-modal").hide();
            jQuery(window).trigger('resize').trigger('scroll');
        });

        // Rapid scan API usage
        $(document).keypress(function (e) {
            if (e.which === 13 && $(".scan_email").is(":focus")) {
                rapid_scan_api($(".free-scan-button").attr('data-modal'));
            }
        });
        $(".Search--home").click(function () {
            rapid_scan_api($(this).attr('data-modal'));
        });
        // Rapid scan widget
        // $("#scan-form").submit(function () {
        //     return false;
        // });
        $("#send-me-tips-checkbox").change(function () {
            update_send_button();
        });

        var error_txt_timer = 0;
        var delay = (function () {
            error_txt_timer = 0;
            return function (callback, ms) {
                clearTimeout(error_txt_timer);
                error_txt_timer = setTimeout(callback, ms);
            };
        })();

        $("#online-check").on("keyup change", function () {
            if (update_send_button()) {
                clearTimeout(error_txt_timer);
                $("#rapid_scan_error_txt").text('');
                $("#rapid_scan_error_container").hide();
                jQuery(window).trigger('resize').trigger('scroll');
            } else {
                delay(function () {
                    $("#rapid_scan_error_container").show();
                    $("#rapid_scan_error_txt").text('Please enter a valid email address');
                    jQuery(window).trigger('resize').trigger('scroll');
                }, 800);
            }

        });

        $("#checkbox_use_email").click(function () {
            var email = $(this).val();
            $("#checkbox_use_email_val").val('');
            if ($(this).is(":checked")) {
                $("#checkbox_use_email_val").val(email);
            }
        });

    });
})(window.jQuery);
