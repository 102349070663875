$("#newsletters_form").submit(function (evt) {
    evt.preventDefault();
    var email = $("#newsletters_email_input").val();
    var name = $("#newsletters_name_input").val();
    fetch(window.APP.url + '/api/marketing/subscribe', {
        method: 'post',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': window.APP.csrf_token
        },

        body: JSON.stringify({email, name})
    }).then(function (resp) {
        const msg = "Thanks for subscribing!";
        toastr.success(msg, null, {timeOut: 10000});
        $("#newsletters_email_input").val("");
        $("#newsletters_name_input").val("");
    })
});
