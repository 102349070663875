import jQuery from "jquery";

window.$ = window.jQuery = jQuery;
import Slideout from "slideout";

$(document).ready(function () {
    if (document.getElementById('panel') === null || document.getElementById('MobileNav') === null) {
        return null;
    }
    var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('MobileNav'),
        'padding': 256,
        'tolerance': 70
    });

// Toggle button
    document.querySelector('.toggle-button').addEventListener('click', function () {
        slideout.toggle();
    });

    function close(eve) {
        eve.preventDefault();
        slideout.close();
    }

    slideout
        .on('beforeopen', function () {
            this.panel.classList.add('panel-open');
        })
        .on('open', function () {
            this.panel.addEventListener('click', close);
        })
        .on('beforeclose', function () {
            this.panel.classList.remove('panel-open');
            this.panel.removeEventListener('click', close);
        });

    $('.MobileNav__item a').click(function () {
        $(this).parent().find('ul').toggleClass('d-block');
    });

    $('.MobileNav__item.MobileNav__item--arrow > a').bind('doubletap', function(){
        window.location = $(this).data("link");
    })
});
