import classie from "../Resources/Classie";

var ModalEffects = (function () {
    function init() {
        var overlay = document.querySelector(".md-overlay");

        [].slice
            .call(document.querySelectorAll(".md-trigger"))
            .forEach(function (el, i) {
                var modal = document.querySelector(
                        "#" + el.getAttribute("data-modal")
                    ),
                    close = modal.querySelector(".md-close"),
                    closeIcon = modal.querySelector(".md-close-icon");

                function removeModal(hasPerspective) {
                    // Remove overflow hidden to body
                    document.body.style.overflow = "";

                    classie.remove(modal, "md-show");

                    if (hasPerspective) {
                        classie.remove(
                            document.documentElement,
                            "md-perspective"
                        );
                    }
                }

                function removeModalHandler() {
                    removeModal(classie.has(el, "md-setperspective"));
                }
                el.addEventListener("click", function (ev) {
                    debugger
                    ev.preventDefault();
                    classie.add(modal, "md-show");

                    overlay.removeEventListener("click", removeModalHandler);
                    overlay.addEventListener("click", removeModalHandler);

                    if (classie.has(el, "md-setperspective")) {
                        setTimeout(function () {
                            classie.add(
                                document.documentElement,
                                "md-perspective"
                            );
                        }, 25);
                    }

                    // Add overflow hidden to body
                    document.body.style.overflow = "hidden";

                    // Escape event listener
                    document.addEventListener("keydown", (e) => {
                        if (e.key === "Escape") {
                            removeModalHandler();
                        }
                    });
                });
                if (close) {
                    close.addEventListener("click", function (ev) {
                        ev.stopPropagation();
                        removeModalHandler();
                    });
                }

                closeIcon.addEventListener("click", function (ev) {
                    ev.stopPropagation();
                    removeModalHandler();
                });
            });
    }

    init();
})();
