//App.js
import React, {Component} from 'react';
import Tab from './Tab';
import {render} from "react-dom";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            boxes: this.props.boxes,
            currentPic: 0
        };
        this.setCurrentPic = this.setCurrentPic.bind(this);
        this.rightImage = React.createRef();
    }

    setCurrentPic(id) {
        this.setState({currentPic: id});

        const fooBarNode = this.rightImage.current;
        fooBarNode.classList.remove('Animate--show');
        setTimeout(() => {
            fooBarNode.classList.add('Animate--show');
        }, 0.001);

    }

    getCurrentPicSrc() {
        for (var i = 0; i < this.state.boxes.length; i++) {
            if (i === this.state.currentPic) {
                return this.state.boxes[i].image;
            }
        }
    }

    render() {
        return (
            <div>
                <div className="container-fluid sm-position-absolute same-height-4">
                    <div className="row">
                        <div className='pl-sm-0 col-12 col-sm-6 col-lg-4-6 flex-middle'>
                            <div className="Tabs__container text-right Animate--delay-elements">
                                {this.state.boxes.map((picture, index) => {
                                    return (
                                        <Tab
                                            key={index}
                                            pictureSrc={picture.image}
                                            setCurrentPic={this.setCurrentPic}
                                            id={index}
                                            title={picture.title}
                                            active={this.state.currentPic === index}
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-big same-height-4">
                    <div className="row h-100">
                        <div className='pl-sm-0 col-12 col-sm-6 col-lg-4 flex-middle'>

                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 pl-sm-0 pt-3 h-100 flex-middle text-center align-items-center">
                            <div className="Tabs__description Animate Animate--fade-up"
                                 dangerouslySetInnerHTML={{__html: this.state.boxes[this.state.currentPic].content}}>
                            </div>
                        </div>
                        <div className='pr-sm-0 col-0 col-md-4 col-lg-4'>
                            <div className="Tabs__img-right-home">
                                <img src={this.getCurrentPicSrc()}
                                     className={`Animate Animate--slide-to-left`}
                                     alt={'iamge'}
                                     ref={this.rightImage}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

let element = document.getElementById("TabsContainer2");

if (element !== null) {
    const props = {...JSON.parse(element.getAttribute('data-props') || '{}'), S3Url: element.getAttribute('data-s3url')};
    render(<Index {...props}/>, document.getElementById("TabsContainer2"));
}
