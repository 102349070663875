import React, { Component } from "react";
import { render } from "react-dom";
import Validation from "fv-validation";
import { counriesCodes } from './country_codes'
// import { Typeahead } from 'react-bootstrap-typeahead';
// import ReCAPTCHA from "react-google-recaptcha";

const options = [
    {
      label: 'Alabama',
      population: 4780127,
      capital: 'Montgomery',
      region: 'South',
    },
    { label: 'Alaska', population: 710249, capital: 'Juneau', region: 'West' },
    { label: 'Arizona', population: 6392307, capital: 'Phoenix', region: 'West' },
    {
      label: 'Arkansas',
      population: 2915958,
      capital: 'Little Rock',
      region: 'South',
    },
    {
      label: 'California',
      population: 37254503,
      capital: 'Sacramento',
      region: 'West',
    },
    { label: 'Colorado', population: 5029324, capital: 'Denver', region: 'West' },
    {
      label: 'Connecticut',
      population: 3574118,
      capital: 'Hartford',
      region: 'Northeast',
    },
    { label: 'Delaware', population: 897936, capital: 'Dover', region: 'South' },
    {
      label: 'Florida',
      population: 18804623,
      capital: 'Tallahassee',
      region: 'South',
    },
    {
      label: 'Georgia',
      population: 9688681,
      capital: 'Atlanta',
      region: 'South',
    },
    { label: 'Hawaii', population: 1360301, capital: 'Honolulu', region: 'West' },
    { label: 'Idaho', population: 1567652, capital: 'Boise', region: 'West' },
    {
      label: 'Illinois',
      population: 12831549,
      capital: 'Springfield',
      region: 'Midwest',
    },
    {
      label: 'Indiana',
      population: 6484229,
      capital: 'Indianapolis',
      region: 'Midwest',
    },
    {
      label: 'Iowa',
      population: 3046869,
      capital: 'Des Moines',
      region: 'Midwest',
    },
    {
      label: 'Kansas',
      population: 2853132,
      capital: 'Topeka',
      region: 'Midwest',
    },
    {
      label: 'Kentucky',
      population: 4339349,
      capital: 'Frankfort',
      region: 'South',
    },
    {
      label: 'Louisiana',
      population: 4533479,
      capital: 'Baton Rouge',
      region: 'South',
    },
    {
      label: 'Maine',
      population: 1328361,
      capital: 'Augusta',
      region: 'Northeast',
    },
    {
      label: 'Maryland',
      population: 5773785,
      capital: 'Annapolis',
      region: 'South',
    },
    {
      label: 'Massachusetts',
      population: 6547817,
      capital: 'Boston',
      region: 'Northeast',
    },
    {
      label: 'Michigan',
      population: 9884129,
      capital: 'Lansing',
      region: 'Midwest',
    },
    {
      label: 'Minnesota',
      population: 5303925,
      capital: 'Saint Paul',
      region: 'Midwest',
    },
    {
      label: 'Mississippi',
      population: 2968103,
      capital: 'Jackson',
      region: 'South',
    },
    {
      label: 'Missouri',
      population: 5988927,
      capital: 'Jefferson City',
      region: 'Midwest',
    },
    { label: 'Montana', population: 989417, capital: 'Alberta', region: 'West' },
    {
      label: 'Nebraska',
      population: 1826341,
      capital: 'Lincoln',
      region: 'Midwest',
    },
    {
      label: 'Nevada',
      population: 2700691,
      capital: 'Carson City',
      region: 'West',
    },
    {
      label: 'New Hampshire',
      population: 1316466,
      capital: 'Concord',
      region: 'Northeast',
    },
    {
      label: 'New Jersey',
      population: 8791936,
      capital: 'Trenton',
      region: 'Northeast',
    },
    {
      label: 'New Mexico',
      population: 2059192,
      capital: 'Santa Fe',
      region: 'West',
    },
    {
      label: 'New York',
      population: 19378087,
      capital: 'Albany',
      region: 'Northeast',
    },
    {
      label: 'North Carolina',
      population: 9535692,
      capital: 'Raleigh',
      region: 'South',
    },
    {
      label: 'North Dakota',
      population: 672591,
      capital: 'Bismarck',
      region: 'Midwest',
    },
    {
      label: 'Ohio',
      population: 11536725,
      capital: 'Columbus',
      region: 'Midwest',
    },
    {
      label: 'Oklahoma',
      population: 3751616,
      capital: 'Oklahoma City',
      region: 'South',
    },
    { label: 'Oregon', population: 3831073, capital: 'Salem', region: 'West' },
    {
      label: 'Pennsylvania',
      population: 12702887,
      capital: 'Harrisburg',
      region: 'Northeast',
    },
    {
      label: 'Rhode Island',
      population: 1052931,
      capital: 'Providence',
      region: 'Northeast',
    },
    {
      label: 'South Carolina',
      population: 4625401,
      capital: 'Columbia',
      region: 'South',
    },
    {
      label: 'South Dakota',
      population: 814191,
      capital: 'Pierre',
      region: 'Midwest',
    },
    {
      label: 'Tennessee',
      population: 6346275,
      capital: 'Nashville',
      region: 'South',
    },
    { label: 'Texas', population: 25146105, capital: 'Austin', region: 'South' },
    {
      label: 'Utah',
      population: 2763888,
      capital: 'Salt Lake City',
      region: 'West',
    },
    {
      label: 'Vermont',
      population: 625745,
      capital: 'Montpelier',
      region: 'Northeast',
    },
    {
      label: 'Virginia',
      population: 8001045,
      capital: 'Richmond',
      region: 'South',
    },
    {
      label: 'Washington',
      population: 6724543,
      capital: 'Olympia',
      region: 'West',
    },
    {
      label: 'West Virginia',
      population: 1853011,
      capital: 'Charleston',
      region: 'South',
    },
    {
      label: 'Wisconsin',
      population: 5687289,
      capital: 'Madison',
      region: 'West',
    },
    { label: 'Wyoming', population: 563767, capital: 'Cheyenne', region: 'West' },
  ];
  

const ToggleButton = ({ isOpen, onClick }) => (
    <button
      className="toggle-button"
      onClick={onClick}
      onMouseDown={(e) => {
        // Prevent input from losing focus.
        e.preventDefault();
      }}>
      {isOpen ? '▲' : '▼'}
    </button>
  );

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                name: '',
                email: '',
                phoneCode: '+44',
                phone: '',
                message: '',
                terms: false,
                // captcha_token: '',
            },
            messageSent: false,
            internalError: false,
            errors: {},
        };
        this.validation = new Validation;
    }

    onChange = event => {
        const { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form });

    };

    handlePhoneCodeChange = event => {
        const { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form });
    }

    onChangeToggle = event => {
        const { form } = this.state;
        form[event.target.name] = !form[event.target.name];
        this.setState({ form });
    };

    // updateCaptchaToken = token => {
    //     const { form } = this.state;
    //     form.captcha_token = token;
    //     this.setState({ form });
    // };

    onSubmit = event => {
        event.preventDefault();
        const { form } = this.state;

        const messages = {
            'phone': {
                // 'uk_mobile': 'The telephone number that you provided is invalid, please provide a valid UK phone number',
                'pattern': 'Your phone number is invalid, please provide a valid phone number'
            },
            'name': {
                'required': 'Your name is required'
            },
            // 'captcha_token': {
            //     'required': 'The captcha is required'
            // },
            'email': {
                'required': 'Your email is required',
                'email': 'Your email is invalid, please provide a valid email address'
            },
            'message': {
                'required': 'Please enter a message',
            },
            'terms': {
                'checked': 'You must agree to the Terms & Conditions'
            }
        };
        const validator = this.validation.validate(this.state.form, {
            name: 'required',
            email: 'required|email',
            message: 'required',
            // captcha_token: 'required',
            terms: 'checked'
        }, messages);

        let errors = {};
        const validatePhone = (phone) => {
            const regExp = /\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/
            if (!regExp.test(phone)) {
                errors['phone'] = 'Your phone number is invalid, please provide a valid phone number'
            }
        }

        validatePhone(this.state.form.phone)

        for (let key in form) {
            if (form.hasOwnProperty(key) && validator.getError(key) !== '') {
                errors[key] = validator.getError(key);
            }
        }
        this.setState({ errors: errors });
        if (!Array.isArray(validator.errors) || !validator.errors.length && !errors.phone) {
            const request = {
                phone: `${this.state.form.phoneCode}${this.state.form.phone}`,
                name: this.state.form.name,
                email: this.state.form.email,
                message: this.state.form.message,
                terms: this.state.form.terms,
                // captcha_token: this.state.form.captcha_token,
            };
            fetch(window.APP.url + '/api/contact', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': window.APP.csrf_token
                },

                body: JSON.stringify(request)
            })
                .then(response => {
                    response.json().then(data => {
                        if (response.status === 422) {
                            this.setState({ errors: data.errors });
                        } else if (response.status === 201) {

                            gtag('event', 'Submit', {
                                'event_category': 'Forms',
                                'event_label': 'Contact us',
                                'value': ''
                            });
                            this.setState({
                                messageSent: true,
                                form: {
                                    phone: '',
                                    name: '',
                                    email: '',
                                    message: '',
                                    terms: false,
                                },
                                internalError: false
                            });
                        } else {
                            this.setState({ messageSent: false, internalError: true });
                        }
                    });
                })
                .catch(function (error) {
                    console.log('Request failed', error);
                });
        }
    }

    render() {
        const { form, errors } = this.state;
        return (
            <div className="Form Form--contact-us mx-auto">
                <form onSubmit={this.onSubmit}>
                    <div className="row Form__row-gutter">
                        <div className="col-12">
                            <div className="Form__title">Get in touch</div>
                            <div className="Form__sub-title">Please provide your contact information and include a
                            detailed message. We aim to respond to all messages within 2-3 business days.
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`Form__input ${errors.hasOwnProperty('name') ? 'Form__input--hasError' : ''}`}
                                    name="name"
                                    placeholder="Full Name*"
                                    value={form.name}
                                    onChange={this.onChange}
                                    tabIndex={1}
                                />
                                {errors.hasOwnProperty('name') && (
                                    <div className="Form__error">
                                        {errors.name}
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`Form__input ${errors.hasOwnProperty('email') ? 'Form__input--hasError' : ''}`}
                                    name="email"
                                    placeholder="Email Address*"
                                    value={form.email}
                                    onChange={this.onChange}
                                    tabIndex={2}
                                />
                                {errors.hasOwnProperty('email') && (
                                    <div className="Form__error">
                                        {errors.email}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-5 col-sm-3">
                                        {/* <Typeahead
                                            filterBy={filterBy}
                                            id="toggle-example"
                                            options={options}
                                            placeholder="Choose a state...">
                                            {({ isMenuShown, toggleMenu }) => (
                                                <ToggleButton isOpen={isMenuShown} onClick={(e) => toggleMenu()} />
                                            )}
                                        </Typeahead> */}
                                        <select
                                            name='phoneCode'
                                            defaultValue={this.state.form.phoneCode}
                                            onChange={this.handlePhoneCodeChange}
                                            className="Form__input countryCodeInput custom-select custom-select-lg" data-size="5" name="phoneCode">
                                            {counriesCodes.map(country => (
                                                <option value={country.dial_code} key={country.code}>{country.dial_code} {country.code}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-7 col-sm-9">
                                        <input
                                            type="text"
                                            className={`Form__input ${errors.hasOwnProperty('phone') ? 'Form__input--hasError' : ''}`}
                                            name="phone"
                                            placeholder="Phone Number"
                                            value={form.phone}
                                            onChange={this.onChange}
                                            tabIndex={3}
                                        />
                                        {errors.hasOwnProperty('phone') && (
                                            <div className="Form__error">
                                                {errors.phone}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group">
                                <textarea
                                    className={`Form__input Form__input--text-area ${errors.hasOwnProperty('message') ? 'Form__input--hasError' : ''}`}
                                    placeholder="Message*"
                                    rows="7"
                                    name="message"
                                    onChange={this.onChange}
                                    value={form.message}
                                    tabIndex={4}
                                >
                                </textarea>

                                {errors.hasOwnProperty('message') && (
                                    <div className="Form__error">
                                        {errors.message}
                                    </div>
                                )}
                            </div>
                        </div>

                        {/* <div className="col-12 col-md-6">
                            <div className="form-group">
                                <ReCAPTCHA
                                    ref="recaptcha"
                                    className="g-recaptcha mb-1"
                                    sitekey={window.APP.captcha_key}
                                    onChange={this.updateCaptchaToken}
                                    tabindex={5}
                                />

                                {errors.hasOwnProperty('captcha_token') && (
                                    <div className="Form__error">
                                        {errors.captcha_token}
                                    </div>
                                )}
                            </div>
                        </div> */}

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <div className="mb-3">
                                    <div className="CheckBox">
                                        <label className="CheckBox__container">I agree to the <a
                                            href={`${window.APP.url + '/terms-of-usage'}`} target="_blank">Terms &
                                            Conditions</a>
                                            <input
                                                type="checkbox"
                                                name="terms"
                                                checked={form.terms}
                                                onChange={this.onChangeToggle}
                                                tabIndex={6}
                                            />
                                            <span className="CheckBox__checkmark" />
                                        </label>
                                    </div>

                                    {errors.hasOwnProperty('terms') && (
                                        <div className="Form__error mt-2">
                                            {errors.terms}
                                        </div>
                                    )}
                                </div>
                                We take data privacy seriously -<br />
                                read our privacy policy for more information <a
                                    href={`${window.APP.url + '/privacy-policy'}`} target="_blank">click here</a>.
                            </div>
                            <div>
                                <label>(*) Required fields</label>
                            </div>
                        </div>

                        <div className="col-12 mt-4 text-center">
                            <button type="submit" className="Form__button">Send
                            Message
                            </button>

                            {this.state.messageSent && (
                                <div className="alert alert-success mt-4" role="alert">
                                    Thank you for your request. We will get back to you very soon!
                                </div>
                            )}

                            {this.state.internalError && (
                                <div className="alert alert-danger mt-4" role="alert">
                                    An internal error has occurred. Please try again later.
                                </div>
                            )}
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}

const nodes = document.querySelectorAll('.component-contact-form');
for (let i = 0; i < nodes.length; i++) {
    const props = JSON.parse(nodes[i].getAttribute('data-props') || '{}');
    render(<ContactForm {...props} />, nodes[i]);
}
