(function($) {

    $.fn.isElementInView = function(fullyInView) {
        var element = this;
        var pageTop = $(window).scrollTop();
        var pageBottom = pageTop + $(window).height();
        var elementTop = $(element).offset().top;
        var elementBottom = elementTop + $(element).height();

        if (fullyInView === true) {
            return ((pageTop < elementTop) && (pageBottom > elementBottom));
        } else {
            return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
        }
    };

    $.fn.attachDragger = function(){
        var attachment = false, lastPosition, position, difference;
        $( $(this).selector ).on("mousedown mouseup mousemove",function(e){
            if( e.type == "mousedown" ) attachment = true, lastPosition = [e.clientX, e.clientY];
            if( e.type == "mouseup" ) attachment = false;
            if( e.type == "mousemove" && attachment == true ){
                position = [e.clientX, e.clientY];
                difference = [ (position[0]-lastPosition[0]), (position[1]-lastPosition[1]) ];
                $(this).scrollLeft( $(this).scrollLeft() - difference[0] );
                $(this).scrollTop( $(this).scrollTop() - difference[1] );
                lastPosition = [e.clientX, e.clientY];
            }
        });
        $(window).on("mouseup", function(){
            attachment = false;
        });
    }

    $.fn.removeStyle = function(style) {
        var elementStyle = this.attr('style');
        if(!elementStyle) {
            return;
        }
        var elementStyles = elementStyle.split(';');
        var styles = {};

        for(var i=0; i<elementStyles.length; i++) {
            var thisStyle = elementStyles[i].split(':');
            if(thisStyle[0].trim()) {
                styles[thisStyle[0].trim()] = thisStyle[1].trim();
            }
        }

        if(typeof style == 'string') {
            if(styles.hasOwnProperty(style)) {
                delete styles[style];
            }
        }

        if(typeof style == 'array') {
            for(var i =0; i<style.length; i++) {
                if(styles.hasOwnProperty(style[i])) {
                    delete styles[style[i]];
                }
            }
        }

        var newStyle = '';
        for(var key in styles) {
            newStyle += key + ': ' + styles[key] + ';';
        }

        this.attr('style', newStyle);
    };

    if(/Trident.*rv[ :]*11\./.test(navigator.userAgent)){
        $('.ie-d-block').css('display','block');
    }
})(jQuery);

function getScrollBarWidth() {
    var inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";

    var outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild (inner);

    document.body.appendChild (outer);
    var w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    var w2 = inner.offsetWidth;
    if (w1 == w2) w2 = outer.clientWidth;

    document.body.removeChild (outer);

    return (w1 - w2);
};

export { getScrollBarWidth };
