//App.js
import React, {Component} from 'react';
import Tab from './Tab';
import {render} from "react-dom";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pictures: [
                {id: 1, src: 'assets/static-img/home_section_1_img_1.png', title: 'How it works', html: 'html content'},
                {id: 2, src: 'assets/static-img/home_section_1_img_1.png', title: 'Choose a plan', html: 'html content'}
            ],
            currentPic: 1,
            fade: false
        };
        this.setCurrentPic = this.setCurrentPic.bind(this);
    }

    setCurrentPic(id) {
        this.setState({currentPic: id});
    }

    getCurrentPicSrc() {
        for (var i = 0; i < this.state.pictures.length; i++) {
            if (this.state.pictures[i].id === this.state.currentPic) {
                return this.state.pictures[i].src;
            }
        }
    }

    render() {
        const fade = this.state.fade
        return (
            <div className="">
                <div className="container-fluid position-absolute">
                    <div className="row">
                        <div className='col-12 col-sm-6'>
                        </div>
                        <div className='col-12 col-sm-6'>
                            <div className="mt-5 mt-lg-0 Animate Animate--slide-to-left">
                                <img src={this.getCurrentPicSrc()} onAnimationEnd={() => this.setState({fade: false})}
                                     className='img-fluid TabsHome__img-right'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className='col-12 col-sm-7'>
                            <hr className="Hr--green Hr--width-85" />
                            <h1 className="mb-0"><strong className="bold">Protect yourself</strong></h1>
                            <h1 className="mb-4">online with DynaRisk</h1>
                            <div className="TabsHome__sub_title">
                                <strong>
                                    Over 90% of people have at least one cyber security
                                    <br />
                                    issue that could leave them vulnerable to
                                    attack.
                                </strong>
                            </div>

                            <br />
                            <div className="TabsHome__container">
                                {this.state.pictures.map((picture) => {
                                    return (
                                        <Tab
                                            key={picture.id}
                                            pictureSrc={picture.src}
                                            setCurrentPic={this.setCurrentPic}
                                            id={picture.id}
                                            title={picture.title}
                                            active={this.currentPic === picture.id}
                                        />
                                    )
                                })}
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                        </div>
                        <div className='col-12 col-sm-7'>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

if (document.getElementById("TabsHomeContainer") !== null) {
    render(<Index/>, document.getElementById("TabsHomeContainer"));
}
