// https://codepen.io/wanderingwind/pen/rNNwgxe
const tabs = document.querySelectorAll("[data-tab-target]");
const tabNav = document.querySelectorAll("[data-tab-nav]");
const tabContents = document.querySelectorAll("[data-tab-content]");
const [prevTabArrow] = document.querySelectorAll(".tab-arrow-prev");
const [nextTabArrow] = document.querySelectorAll(".tab-arrow-next");
const [tabsContainer] = document.querySelectorAll(".solutions-tabs");

if (nextTabArrow) {
    nextTabArrow.addEventListener("click", () => {
        const scrollLeft = tabsContainer.scrollLeft;
        const scrollWidth = tabsContainer.scrollWidth;
        const offsetWidth = tabsContainer.offsetWidth;
        const scrollPos = scrollLeft + offsetWidth;

        const left =
            scrollWidth - scrollPos < 300 ? scrollWidth : scrollLeft + 250;

        tabsContainer.scrollTo({
            left,
            behavior: "smooth",
        });
    });
}
if (prevTabArrow) {
    prevTabArrow.addEventListener("click", () => {
        const scrollLeft = tabsContainer.scrollLeft;

        const left = scrollLeft < 300 ? 0 : scrollLeft - 250;

        tabsContainer.scrollTo({
            left,
            behavior: "smooth",
        });
    });
}

if (tabsContainer) {
    tabsContainer.addEventListener("scroll", () => {
        const scrollLeft = tabsContainer.scrollLeft;
        const scrollWidth = tabsContainer.scrollWidth;
        const offsetWidth = tabsContainer.offsetWidth;
        const scrollPos = scrollLeft + offsetWidth;
        if (scrollLeft === 0) {
            prevTabArrow.className = "tabs-arrow tab-arrow-prev not-active";
        }
        if (scrollLeft > 0) {
            prevTabArrow.className = "tabs-arrow tab-arrow-prev";
        }
        if (scrollPos === scrollWidth) {
            nextTabArrow.className = "tabs-arrow tab-arrow-next not-active";
        }
        if (scrollPos < scrollWidth) {
            nextTabArrow.className = "tabs-arrow tab-arrow-next";
        }
    });
}

tabs.forEach((tab) => {
    tab.addEventListener("click", () => {
        const target = document.querySelector(tab.dataset.tabTarget);
        let activeNav = null;
        tabContents.forEach((tabContent) => {
            tabContent.classList.remove("active");
        });
        tabs.forEach((tab) => {
            tab.classList.remove("active");
        });
        if (tabNav.length) {
            tabNav.forEach((nav) => {
                if (nav.dataset.tabNav === tab.dataset.tabTarget) {
                    activeNav = nav;
                }
                nav.classList.remove("active");
            });
            activeNav.classList.add("active");
        }

        tab.classList.add("active");
        target.classList.add("active");
    });
});

tabNav.forEach((tab) => {
    tab.addEventListener("click", () => {
        const target = document.querySelector(tab.dataset.tabNav);
        let activeNav = null;
        tabContents.forEach((tabContent) => {
            tabContent.classList.remove("active");
        });
        tabNav.forEach((tab) => {
            tab.classList.remove("active");
        });
        tabs.forEach((nav) => {
            if (nav.dataset.tabTarget === tab.dataset.tabNav) {
                activeNav = nav;
            }
            nav.classList.remove("active");
        });
        activeNav.classList.add("active");

        tab.classList.add("active");
        target.classList.add("active");
    });
});
