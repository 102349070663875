$(document).ready(function(){
    $('.Button__link.Button__link--transparent').click(function(e){
        e.preventDefault();
        var tab_id = $(this).attr('data-tab');
        $(this).addClass('Button__link--active');

        if(tab_id ==='business-packages'){
            $('#home-packages').addClass('d-none');
            $('#home-packages').removeClass('d-block');
            $("a[data-tab='home-packages']").removeClass('Button__link--active');


        }else if(tab_id ==='home-packages'){
            $('#business-packages').addClass('d-none');
            $('#business-packages').removeClass('d-block');
            $("a[data-tab='business-packages']").removeClass('Button__link--active');
        }
        $("#"+tab_id).addClass('d-block');
        $(window).trigger('scroll');
        $(window).resize();
    })
});