const videoEl = document.querySelector('#how-it-works-video');
const videoOverlayEl = document.querySelector('#video-overlay');

if(videoOverlayEl) {
  videoOverlayEl.addEventListener('click', toggleVideoPlayback);
}

function toggleVideoPlayback(evt) {
  if (!videoEl.paused) {
    videoOverlayEl.classList.add('paused');
    videoEl.pause();
    return
  }
  videoOverlayEl.classList.remove('paused');
  return videoEl.play();
}