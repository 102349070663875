$(document).ready(function () {
    var nodes = document.querySelectorAll( 'oembed[url]' );
    for(var i = 0; i < nodes.length; i++){
        var videoUrl = nodes[i].attributes.url.value;
        var isYouTube = isYouTubeLink(nodes[i].attributes.url.value);
        if(isYouTube){
            $(nodes[i]).html('<iframe width="560" height="315" src="//www.youtube.com/embed/' + getYTVideoId(videoUrl) + '" frameborder="0" allowfullscreen></iframe>');
        }else{
            $(nodes[i]).html('<iframe width="560" height="315" src="'+videoUrl+'" frameborder="0" allowfullscreen></iframe>');
        }
    }

    function isYouTubeLink(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);

        if (match && match[2].length == 11) {
            return true;
        } else {
            return false;
        }
    }
    function getYTVideoId(url) {
        var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
        var match = url.match(regExp);

        if (match && match[2].length == 11) {
            return match[2];
        }
    }
});
