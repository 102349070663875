$(document).ready(function () {
    window.__ss_noform = window.__ss_noform || [];

    let images = $('figure[class ="image"]');
    if(images.find('figcaption').length > 0){
        $.each(images, function( index, value ) {
            let figCaption = $(value).find('figcaption');
            if(figCaption.length > 0){
                let a = figCaption.find('a');
                if(a.length > 0){
                    a.parent().parent().find('img').wrap($('<a>',{
                        href: a.attr("href")
                    }));
                }
            }
        });
    }


    $('#breach-defence-book-a-demo').submit(e => {

        e.preventDefault();
        const urlparams = $('#breach-defence-book-a-demo').serialize();
        $.ajax({
            url: 'https://app-3QNLJ406OU.marketingautomation.services/webforms/receivePostback/MzawMLEwMDI0BwA/e92f7e04-dbd8-4d1c-b0a9-8a754cb237c1/jsonp/?'+urlparams,
            contentType: "application/json",
            dataType: 'jsonp',
            success: function(json) {
                // showSuccessMessage();
            },
            error: function(e) {
                // showSuccessMessage();
            },
            complete: function () {
                if (window.fbq) {
                    fbq('track', 'Lead', {currency: "USD", value: 10.00});
                }
                if (window.__ss_noform['success']) {
                    $('#book-a-demo-form').hide();
                    $(".custom-icon-success").removeClass("d-none");
                }
            }
        });

    });

    $('#book-a-demo-form').submit(e => {

        e.preventDefault();
        const urlparams = $('#book-a-demo-form').serialize();
        $.ajax({
            url: 'https://app-3QNLJ406OU.marketingautomation.services/webforms/receivePostback/MzawMLEwMDI0BwA/4ccccbc9-f15c-4469-8bdd-f35ff539e475/jsonp/?'+urlparams,
            contentType: "application/json",
            dataType: 'jsonp',
            success: function(json) {
                // showSuccessMessage();
            },
            error: function(e) {
                // showSuccessMessage();
            },
            complete: function () {
                if (window.__ss_noform['success']) {
                    $('#book-a-demo-form').hide();
                    $(".custom-icon-success").removeClass("d-none");
                }
            }
        });

    });
});

function showSuccessMessage () {
    $('#breach-defence-book-a-demo').hide();
    $(".custom-icon-success").removeClass("d-none");
}
