import React from "react";
import Slider from "react-slick";
import {render} from "react-dom";

function NextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <img src={'../../assets/static-img/arrow_right.png'}
             alt={'Arrow Right'}
             className={className} onClick={onClick}/>
    );
}

function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <img src={'../../assets/static-img/arrow_left.png'}
             alt={'Arrow Left'}
             className={className} onClick={onClick}/>
    );
}

class ReactCarousel extends React.Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            swipe: false,
            draggable: false,
            className: 'custom-slider',
        };

        return (
            <div className="Carousel__bg">
                <Slider ref={c => (this.slider = c)}  {...settings} >
                    {
                        this.props.elements.map((element, index) => {
                            return (
                                <div key={index}>
                                    <div className="container">
                                        <div className="row pb-5 pt-3 pt-md-5">
                                            <div className="col-12 col-sm-7-7 Animate--delay-elements">
                                                <div className="Animate Animate--slide-to-right">
                                                    <span
                                                        className="Carousel__title Carousel__title--small">HOW IT WORKS</span>
                                                </div>
                                                <div>
                                                    <h1 className="Carousel__title mb-0 Animate Animate--slide-to-right">{element.heading}</h1>
                                                </div>
                                                <hr className="Hr--green Hr--0 mb-2 Animate Animate--grow-to-180"/>
                                                <div className="Carousel__description">
                                                    <div className="Animate Animate--slide-to-right"
                                                         dangerouslySetInnerHTML={{__html: element.content}} />
                                                </div>
                                            </div>
                                            <div className="col-12 col-sm-4-3 flex-middle">
                                                <div className="Carousel__circle Animate Animate--slide-to-left">
                                                    <figure
                                                        style={{
                                                            backgroundImage: "url(" + element.image + ")"
                                                        }}
                                                    >
                                                        <figcaption
                                                            style={{
                                                                backgroundImage: "url(" + element.icon + ")"
                                                            }}
                                                        >
                                                        </figcaption>
                                                    </figure>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Slider>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="arrowsContainer" style={{textAlign: "center", position: "relative"}}>
                                <img
                                    src={'../../assets/static-img/arrow_left.png'}
                                    alt={'Arrow Left'}
                                    className="slick-prev" onClick={this.previous}/>
                                <img
                                    src={'../../assets/static-img/arrow_right.png'}
                                    alt={'Arrow Right'}
                                    className="slick-next" onClick={this.next}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

let element = document.getElementById("ReactCarousel");
if (element !== null) {
    const props = {...JSON.parse(element.getAttribute('data-props') || '{}')};
    render(<ReactCarousel {...props}/>, document.getElementById("ReactCarousel"));
}
