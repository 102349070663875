import jQuery from "jquery";

window.$ = window.jQuery = jQuery;
import matchHeight from "jquery-match-height";

$(function () {
    $('#SameHeightElements > *').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });
    sameHeightElements();
    $(window).on('resize', function () {
        sameHeightElements();
    });

});

function sameHeightElements() {

    $('.VideoCard__description').matchHeight({
        byRow: false,
        property: 'height',
        target: null,
        remove: false
    });
    if (window.matchMedia('(min-width: 992px)').matches) {
        $('.same-height').matchHeight({
            byRow: false,
            property: 'height',
            target: null,
            remove: false
        });
        $('.same-height-2').matchHeight({
            byRow: false,
            property: 'height',
            target: null,
            remove: false
        });
        $('.same-height-3').matchHeight({
            byRow: false,
            property: 'height',
            target: null,
            remove: false
        });
        $('.same-height-5').matchHeight({
            byRow: false,
            property: 'height',
            target: null,
            remove: false
        });
        $('.EarthBgSection').matchHeight({
            byRow: false,
            property: 'height',
            target: null,
            remove: false
        });
        $('.Package__wrapper').matchHeight({
            byRow: false,
            property: 'height',
            target: null,
            remove: false
        });
        $('.VideoCard__description').matchHeight({
            byRow: false,
            property: 'height',
            target: null,
            remove: false
        });

        for (let i = 0; i < 20; i++) {
            $(`.Package__item:nth-child(${i})`).matchHeight({
                byRow: false,
                property: 'height',
                target: null,
                remove: false
            });
        }
    }
    if (window.matchMedia('(min-width: 576px)').matches) {
        $('.same-height-4').matchHeight({
            byRow: false,
            property: 'height',
            target: null,
            remove: false
        });
    }
}