$(document).ready(function () {
    const $testimonialContainer = $(".testmon");
    if ($testimonialContainer.length) {
        $testimonialContainer.slick({
            centerMode: true,
            centerPadding: "106px",
            autoplay: true,
            arrows: true,
            slidesToScroll: 1,
            slidesToShow: 1,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 1250,
                    settings: {
                        centerMode: true,
                        centerPadding: "106px",
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        centerMode: true,
                        centerPadding: "16px",
                        slidesToShow: 1,
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        arrows: false,
                        centerMode: true,
                        centerPadding: "4px",
                        slidesToShow: 1,
                        dots: true,
                    },
                },
            ],
        });
    }
});
