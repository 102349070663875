import React, {Component} from "react";
import PropTypes from "prop-types";
import {render} from "react-dom";
import Validation from "fv-validation";

class PartnersSalesContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                first_name: '',
                last_name: '',
                company_name: '',
                company_size: '',
                company_url: '',
                email: '',
                phone: '',
                message: '',
                product: this.props.product,
                solution: this.props.solution,
                terms: false,

            },
            messageSent: false,
            internalError: false,
            errors: {},
        };
        this.validation = new Validation();

        Validation.addRule('domain', (input, name, message) => ({
            passed: input.length === 0 || /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(input),
            error: message || `The ${name} field must be a valid domain`
        }));
    }

    static propTypes = {
        type: PropTypes.oneOf(['product', 'solution']).isRequired,
        product: PropTypes.string,
        solution: PropTypes.string
    };

    static defaultProps = {
        product: '',
        solution: ''
    };

    onChange = event => {
        const {form} = this.state;
        form[event.target.name] = event.target.value;
        this.setState({form});

    };

    onChangeToggle = event => {
        const {form} = this.state;
        form[event.target.name] = !form[event.target.name];
        this.setState({form});
    };

    onSubmit = event => {
        event.preventDefault();

        const {form} = this.state;

        const messages = {
            'phone': {
                'required': 'Your phone is required',
                'uk_mobile': 'The telephone number that you provided is invalid, please provide a valid UK phone number'
            },
            'first_name': {
                'required': 'Your first name is required'
            },
            'last_name': {
                'required': 'Your last name is required'
            },
            'product': {
                'required': 'Please select a product'
            },
            'solution': {
                'required': 'Please select a solution'
            },
            'email': {
                'required': 'Your email is required',
                'email': 'Your email is invalid, please provide a valid email address'
            },
            'company_name': {
                'required': 'Your company name is required'
            },
            'company_size': {
                'required': 'Your company size is required'
            },
            'domain': {
                'required': 'Your company URL is required',
                'domain': 'The URL you provided is invalid, please provide a valid URL'
            },
            'terms': {
                'checked': 'You must agree to the Terms & Conditions'
            }
        };

        const rules = {
            phone: 'required|uk_mobile',
            first_name: 'required',
            last_name: 'required',
            email: 'required|email',
            company_name: 'required',
            company_size: 'required',
            company_url: 'required|domain',
            terms: 'checked'
        };

        if (this.props.type === 'product') {
            rules.product = 'required';
        } else if (this.props.type === 'solution') {
            rules.solution = 'required';
        }

        const validator = this.validation.validate(this.state.form, rules, messages);

        let errors = {};

        for (let key in form) {
            if (form.hasOwnProperty(key) && validator.getError(key) !== '') {
                errors[key] = validator.getError(key);
            }
        }
        this.setState({errors: errors});

        if (!Array.isArray(validator.errors) || !validator.errors.length) {
            const request = {
                phone: form.phone,
                first_name: form.first_name,
                last_name: form.last_name,
                product: form.product,
                solution: form.solution,
                company_name: form.company_name,
                company_size: form.company_size,
                company_url: form.company_url,
                email: form.email,
                message: form.message,
                terms: form.terms,
            };

            fetch(window.APP.url + '/api/partners/sales-request', {
                method: 'post',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': window.APP.csrf_token
                },

                body: JSON.stringify(request)
            })
                .then(response => {
                    response.json().then(data => {
                        if (response.status === 422) {
                            this.setState({errors: data.errors});
                        } else if (response.status === 201) {

                            gtag('event', 'Submit', {
                                'event_category': 'Forms',
                                'event_label': 'Partners Sales',
                                'value': ''
                            });
                            this.setState({
                                messageSent: true,
                                form: {
                                    phone: '',
                                    first_name: '',
                                    last_name: '',
                                    product: '',
                                    solution: '',
                                    company_name: '',
                                    company_url: '',
                                    company_size: '',
                                    email: '',
                                    message: '',
                                    terms: false,
                                },
                                internalError: false
                            });
                        } else {
                            this.setState({messageSent: false, internalError: true});
                        }
                    });
                })
                .catch(function (error) {
                    console.log('Request failed', error);
                });
        }
    }

    render() {
        const {form, errors} = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.onSubmit}>
                    <div className="row Form__row-gutter">
                        <div className="col-12">
                            <div className="form-group">

                                {this.props.type === 'product' ? (
                                  <>
                                      <select
                                          className={`Form__input Form__input--select ${errors.hasOwnProperty('product') ? 'Form__input--hasError' : ''}`}
                                          name="product"
                                          onChange={this.onChange}
                                          tabIndex={1}
                                          defaultValue={form.product}
                                      >
                                          <option value="">Product*</option>
                                          <option value="cyber_security_score">Cyber Security Score</option>
                                          <option value="cyber_risk_finder">Cyber Risk Finder</option>
                                          <option value="helpline">Helpline</option>
                                          <option value="breach_check_api">Breach Check API</option>
                                      </select>
                                      {errors.hasOwnProperty('product') && (
                                          <div className="Form__error">
                                              {errors.product}
                                          </div>
                                      )}
                                  </>
                                ) : (
                                    <>
                                        <select
                                            className={`Form__input Form__input--select ${errors.hasOwnProperty('solution') ? 'Form__input--hasError' : ''}`}
                                            name="solution"
                                            onChange={this.onChange}
                                            tabIndex={1}
                                            defaultValue={form.solution}
                                        >
                                            <option value="">Solution*</option>
                                            <option value="for_insurance_providers">For insurance providers</option>
                                            <option value="for_financial_services">For financial services</option>
                                            <option value="for_brokers">For brokers</option>
                                        </select>
                                        {errors.hasOwnProperty('solution') && (
                                            <div className="Form__error">
                                                {errors.solution}
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`Form__input ${errors.hasOwnProperty('first_name') ? 'Form__input--hasError' : ''}`}
                                    name="first_name"
                                    placeholder="First Name*"
                                    value={form.first_name}
                                    onChange={this.onChange}
                                    tabIndex={2}
                                    autoFocus
                                />
                                {errors.hasOwnProperty('first_name') && (
                                    <div className="Form__error">
                                        {errors.first_name}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`Form__input ${errors.hasOwnProperty('last_name') ? 'Form__input--hasError' : ''}`}
                                    name="last_name"
                                    placeholder="Last Name*"
                                    value={form.last_name}
                                    onChange={this.onChange}
                                    tabIndex={3}
                                />
                                {errors.hasOwnProperty('last_name') && (
                                    <div className="Form__error">
                                        {errors.last_name}
                                    </div>
                                )}
                            </div>
                        </div>


                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`Form__input ${errors.hasOwnProperty('email') ? 'Form__input--hasError' : ''}`}
                                    name="email"
                                    placeholder="Email Address*"
                                    value={form.email}
                                    onChange={this.onChange}
                                    tabIndex={4}
                                />
                                {errors.hasOwnProperty('email') && (
                                    <div className="Form__error">
                                        {errors.email}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`Form__input ${errors.hasOwnProperty('phone') ? 'Form__input--hasError' : ''}`}
                                    name="phone"
                                    placeholder="Phone Number*"
                                    value={form.phone}
                                    onChange={this.onChange}
                                    tabIndex={5}
                                />
                                {errors.hasOwnProperty('phone') && (
                                    <div className="Form__error">
                                        {errors.phone}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`Form__input ${errors.hasOwnProperty('company_name') ? 'Form__input--hasError' : ''}`}
                                    name="company_name"
                                    placeholder="Company Name*"
                                    value={form.company_name}
                                    onChange={this.onChange}
                                    tabIndex={7}
                                />
                                {errors.hasOwnProperty('company_name') && (
                                    <div className="Form__error">
                                        {errors.company_name}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className={`Form__input ${errors.hasOwnProperty('company_url') ? 'Form__input--hasError' : ''}`}
                                    name="company_url"
                                    placeholder="Company URL*"
                                    value={form.company_url}
                                    onChange={this.onChange}
                                    tabIndex={8}
                                />
                                {errors.hasOwnProperty('company_url') && (
                                    <div className="Form__error">
                                        {errors.company_url}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group">
                                <select
                                    className={`Form__input Form__input--select ${errors.hasOwnProperty('company_size') ? 'Form__input--hasError' : ''}`}
                                    name="company_size"
                                    onChange={this.onChange}
                                    tabIndex={9}
                                    value={form.company_size}
                                >
                                    <option value="">Company Size*</option>
                                    <option value="1-49">1-49</option>
                                    <option value="50-249">50-249</option>
                                    <option value="250+">250+</option>
                                </select>
                                {errors.hasOwnProperty('company_size') && (
                                    <div className="Form__error">
                                        {errors.company_size}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12">
                            <div className="form-group">
                                <textarea
                                    className={`Form__input Form__input--text-area ${errors.hasOwnProperty('message') ? 'Form__input--hasError' : ''}`}
                                    placeholder="Message"
                                    rows="7"
                                    name="message"
                                    onChange={this.onChange}
                                    value={form.message}
                                    tabIndex={10}
                                >
                                    </textarea>

                                {errors.hasOwnProperty('message') && (
                                    <div className="Form__error">
                                        {errors.message}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-6">
                            <div className="form-group">
                                <div className="mb-3">
                                    <div className="CheckBox">
                                        <label className="CheckBox__container">I agree to the <a
                                            href={`${window.APP.url + '/terms-of-usage'}`} target="_blank">Terms &
                                            Conditions</a>
                                            <input
                                                type="checkbox"
                                                name="terms"
                                                checked={form.terms}
                                                onChange={this.onChangeToggle}
                                                tabIndex={11}
                                            />
                                            <span className="CheckBox__checkmark"/>
                                        </label>
                                    </div>

                                    {errors.hasOwnProperty('terms') && (
                                        <div className="Form__error mt-2">
                                            {errors.terms}
                                        </div>
                                    )}
                                </div>
                                We take data privacy seriously -<br/>
                                read our privacy policy for more information <a
                                href={`${window.APP.url + '/privacy-policy'}`} target="_blank">click here</a>.
                            </div>
                            <div>
                                <label>(*) Required fields</label>
                            </div>
                        </div>

                        <div className="col-12 col-md-6 mt-4 text-center">
                            <button type="submit" className="Form__button">Contact sales</button>
                        </div>

                        {this.state.messageSent && (
                            <div className="col-12 text-center">
                                <div className="alert alert-success mt-4" role="alert">
                                    Thank you for your request. We will get back to you very soon!
                                </div>
                            </div>
                        )}

                        {this.state.internalError && (
                            <div className="col-12 text-center">
                                <div className="alert alert-danger mt-4" role="alert">
                                    An internal error has occurred. Please try again later.
                                </div>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        )
    }
}

const nodes = document.querySelectorAll('.component-partners-sales-contact-form');
for (let i = 0; i < nodes.length; i++) {
    const props = JSON.parse(nodes[i].getAttribute('data-props') || '{}');
    render(<PartnersSalesContactForm {...props}/>, nodes[i]);
}
