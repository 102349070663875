//App.js
import React, {Component} from 'react';
import Tab from './Tab';
import {render} from "react-dom";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pictures: this.props.pictures,
            currentPic: 1,
            fade: false
        };
        this.setCurrentPic = this.setCurrentPic.bind(this);
    }

    setCurrentPic(id) {
        this.setState({currentPic: id});
    }

    getCurrentPicSrc() {
        for (var i = 0; i < this.state.pictures.length; i++) {
            if (this.state.pictures[i].id === this.state.currentPic) {
                return this.state.pictures[i].src;
            }
        }
    }

    render() {
        const fade = this.state.fade
        return (
            <div className="row">
                <div className='col-12 col-sm-6-5'>
                    <h1 className="mb-0"><strong className="bold">{this.props.title}</strong></h1>
                    <h3 dangerouslySetInnerHTML={{__html: this.props.sub_title}}/>
                    <div className="Tabs__container">
                        {this.state.pictures.map((picture) => {
                            return (
                                <Tab
                                    key={picture.id}
                                    pictureSrc={picture.src}
                                    setCurrentPic={this.setCurrentPic}
                                    id={picture.id}
                                    title={picture.title}
                                    active={this.currentPic === picture.id}
                                />
                            )
                        })}
                    </div>
                </div>
                <div className='col-12 col-sm-5-5'>
                    <div className="Device Device__business--1-right">
                        <img src={this.getCurrentPicSrc()} className="Animate Animate--slide-to-left Animate--show" />
                    </div>


                    {/*<div className="mt-5 mt-lg-0 Animate Animate--slide-to-left">*/}
                    {/*    <img src={this.getCurrentPicSrc()} onAnimationEnd={() => this.setState({fade: false})}*/}
                    {/*         className='img-fluid Section__hero-img Section__hero-img--1'/>*/}
                    {/*</div>*/}
                </div>
            </div>
        )
    }
}
let element = document.getElementById("TabsContainer");
if (element !== null) {
    const props = JSON.parse(element.getAttribute('data-props') || '{}');
    render(<Index {...props}/>, document.getElementById("TabsContainer"));
}