require('paroller.js');
import  "../Resources/numscroller"

$("#parallax_1").paroller({
    factor: 1,            // multiplier for scrolling speed and offset
    // factorXs: -0.1,           // multiplier for scrolling speed and offset
    // factorSm: -0.2,           // multiplier for scrolling speed and offset
    type: 'background',     // background, foreground
    direction: 'vertical', transition: 'transform 0.1s ease'
});
if (window.matchMedia('(min-width: 992px)').matches) {
    $("#parallax_bg").paroller({
        factor: 0.5,            // multiplier for scrolling speed and offset
        // factorXs: -0.1,           // multiplier for scrolling speed and offset
        // factorSm: -0.2,           // multiplier for scrolling speed and offset
        type: 'background',     // background, foreground
        direction: 'vertical', transition: 'transform 0.1s ease'
    });
    $("#parallax_family").paroller({
        factor: 0.7,            // multiplier for scrolling speed and offset
        // factorXs: -0.1,           // multiplier for scrolling speed and offset
        // factorSm: -0.2,           // multiplier for scrolling speed and offset
        type: 'background',     // background, foreground
        direction: 'vertical', transition: 'transform 0.1s ease'
    });
}

/**
 * Check if elements with class 'Animate' are in the viewport. If so, add a class 'Animate--show' to them.
 * Animations are defined in Animations.scss
 */

const $animation_elements = $('.Animate');
const $window = $(window);

function check_if_in_view() {
    const window_height = $window.height();
    const window_top_position = $window.scrollTop();
    const window_bottom_position = (window_top_position + window_height);

    $.each($animation_elements, function () {
        const $element = $(this);
        const element_height = $element.outerHeight();
        const element_top_position = $element.offset().top;
        const element_bottom_position = (element_top_position + element_height);

        //check to see if this current container is within viewport
        if ((element_bottom_position >= window_top_position) &&
            (element_top_position <= window_bottom_position)) {
            $element.addClass('Animate--show');

            // Count numbers
            if ($element.hasClass('Animate--count-numbers')
                && typeof $element.data('from') === 'number'
                && typeof $element.data('to') === 'number'
            ) {
                $element.removeClass('Animate--count-numbers');
                $element.css('opacity', 1);
                const suffix = $element.data('suffix');
                const precision = $element.data('precision');
                count_numbers($element, $element.data('from'), $element.data('to'), suffix, precision);
            }
        }
    });
}

/**
 * Number increment animation
 * @type {number}
 */
const NUMBER_INTERVAL = 30; // Time in ms between each number increment
function count_numbers($element, from, to, suffix = '', precision = 1) {
    const timer = setInterval(function () {
        let currentCount = Number.parseFloat($element.html());
        if (currentCount < from) {
            $element.html(from + suffix);
        } else if (currentCount >= to) {
            clearInterval(timer);
        } else {
            currentCount = currentCount + precision;
            let number;
            if (precision !== 1) {
                number = currentCount.toFixed(1);
            } else {
                number = currentCount;
            }
            $element.html(number + suffix);
        }
    }, NUMBER_INTERVAL);
}


$(document).ready(function () {
    $window.on('scroll resize', check_if_in_view);
    $window.trigger('scroll');
});