import React, {Component} from 'react';

class Tab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pictureId: null,
            title: null,
            active: false
        };
        this.buttonLabel = this.buttonLabel.bind(this);
    }

    buttonLabel() {
        this.setState({pictureId: this.props.id, title: this.props.title})
        //this.setState({active: this.props.active})
    }

    handleClick(e) {
        this.props.setCurrentPic(this.props.id);
        // modify the state, this will automatically recall render() below.
        this.setState((prevState) => {
            return {active: !prevState.active}
        });
    }

    componentDidMount() {
        this.buttonLabel(this.props.pictureSrc);
    }

    render() {
        let activeClass = (this.props.active ? ' Tab__item--active' : '');

        return (
            <div className="Tab">
                <div className="Animate Animate--slide-to-right">
                    <div
                        className={`Tab__item Tab__item${activeClass} Tab__item--v2`}
                        onClick={() => this.handleClick()
                        }
                    >
                        <div className="Tab__line-decoration-v2"></div>
                        {this.state.title}
                    </div>
                </div>
            </div>
        )
    }
}

export default Tab;