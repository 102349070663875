import '../Resources/jquery.liMarquee';

$('.scroll').width($(window).width());

$(window).on('load', function () {
    $('.scroll').liMarquee(
        {
            direction: 'left',
            loop: -1,
            scrolldelay: 0,
            scrollamount: 20,
            circular: true,
            drag: true,
            runshort: true,
            hoverstop: true,
            xml: false,
            inverthover: false
        }
    );
});