$(document).ready(function () {
    $('.ProtectCard__title_text').each(function(){
        //Make bold first word in sentence
        var node = $(this).contents().filter(function () { return this.nodeType == 3 }).first(),
            text = node.text(),
            firstWord = text.slice(0, text.indexOf(" "));

        if (!node.length || text.trim().indexOf(' ') == -1)
            return;

        node[0].nodeValue = text.slice(firstWord.length);
        node.before('<b>' + firstWord + '</b>');
    });
    $(window).trigger('resize');
});
