$("nav .Nav__item").hover(
    function () {
        var [activeMenuLinkEl] = $(this).find("ul.sub-menu1>li>a.active");
        var parentActive = null;
        var activeMenuList = null;
        if (activeMenuLinkEl) {
            
            parentActive = $(activeMenuLinkEl).parent()[0];
            activeMenuList = $(parentActive).find("ul")[0];
        }

        $(this)
            .find("ul.sub-menu1:not(:visible)")
            .slideDown({
                duration: 200,
                start: () => {
                    if (activeMenuList) {
                        $(activeMenuLinkEl).addClass('parent-with-sub-menu')
                        $(activeMenuList).slideDown({ duration: 200 });
                    }
                    setTimeout(() => {
                        $(this).find("ul.sub-menu1").addClass("green-border");
                    }, 150);
                },
            });
    },
    function () {
        $(this)
            .find("ul.sub-menu1:visible")
            .slideUp({
                duration: 200,
                start: () => {
                    setTimeout(() => {
                        $(this)
                            .find("ul.sub-menu1")
                            .removeClass("green-border");
                    }, 100);
                },
            });
        $(".sub-menu2").slideUp({ duration: 200 });
    }
);
$(".login").hover(
    function () {
        $(this)
            .find(".Button__dropdown:not(:visible)")
            .slideDown({
                duration: 200,
                start: () => {
                    setTimeout(() => {
                        $(this)
                            .find(".Button__dropdown")
                            .addClass("green-border");
                    }, 150);
                },
            });
    },
    function () {
        $(this)
            .find(".Button__dropdown:visible")
            .slideUp({
                duration: 200,
                start: () => {
                    setTimeout(() => {
                        $(this)
                            .find(".Button__dropdown")
                            .removeClass("green-border");
                    }, 100);
                },
            });
    }
);
$(".sub-menu1 li").hover(function () {
    $(this).find(".sub-menu2").slideDown({ duration: 200 });
});
