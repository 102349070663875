const tabs = document.querySelectorAll("[data-tab-btn]");
const dotNavs = document.querySelectorAll("[data-dot-nav]");
const tabContents = document.querySelectorAll("[data-tab-content]");
const slideWrapper = document.querySelector("#slide-wrapper");

const [sliderPrevTabArrow] = document.querySelectorAll(
    ".slider-tab-arrow-prev"
);
const [sliderNextTabArrow] = document.querySelectorAll(
    ".slider-tab-arrow-next"
);
const [sliderTabsContainer] = document.querySelectorAll(".slide-navigation");

if (sliderNextTabArrow) {
    sliderNextTabArrow.addEventListener("click", () => {
        const scrollLeft = sliderTabsContainer.scrollLeft;
        const scrollWidth = sliderTabsContainer.scrollWidth;
        const offsetWidth = sliderTabsContainer.offsetWidth;
        const scrollPos = scrollLeft + offsetWidth;

        const left =
            scrollWidth - scrollPos < 300 ? scrollWidth : scrollLeft + 250;

        sliderTabsContainer.scrollTo({
            left,
            behavior: "smooth",
        });
    });
}
if (sliderPrevTabArrow) {
    sliderPrevTabArrow.addEventListener("click", () => {
        const scrollLeft = sliderTabsContainer.scrollLeft;

        const left = scrollLeft < 300 ? 0 : scrollLeft - 250;

        sliderTabsContainer.scrollTo({
            left,
            behavior: "smooth",
        });
    });
}

if (sliderTabsContainer) {
    sliderTabsContainer.addEventListener("scroll", () => {
        const scrollLeft = sliderTabsContainer.scrollLeft;
        const scrollWidth = sliderTabsContainer.scrollWidth;
        const offsetWidth = sliderTabsContainer.offsetWidth;
        const scrollPos = scrollLeft + offsetWidth;
        if (scrollLeft === 0) {
            sliderPrevTabArrow.className =
                "slider-tabs-arrow slider-tab-arrow-prev not-active";
        }
        if (scrollLeft > 0) {
            sliderPrevTabArrow.className =
                "slider-tabs-arrow slider-tab-arrow-prev";
        }
        if (Math.round(scrollPos) === scrollWidth) {
            sliderNextTabArrow.className =
                "slider-tabs-arrow slider-tab-arrow-next not-active";
        }
        if (Math.round(scrollPos) < scrollWidth) {
            sliderNextTabArrow.className =
                "slider-tabs-arrow slider-tab-arrow-next";
        }
    });
}

(function () {
    tabs.forEach((tab) => {
        tab.addEventListener("click", () => {
            const target = document.querySelector(tab.dataset.tabBtn);
            if (tab.classList.contains("active")) {
                return;
            }
            tabs.forEach((tab) => {
                tab.classList.remove("active");
            });
            tabContents.forEach((tabContent) => {
                tabContent.classList.remove("active");
            });
            dotNavs.forEach((dot) => {
                dot.classList.remove("active");
                if (dot.dataset.dotNav === tab.dataset.tabBtn) {
                    dot.classList.add("active");
                }
            });
            tab.classList.add("active");
            target.classList.add("active");
        });
    });

    dotNavs.forEach((tab) => {
        tab.addEventListener("click", () => {
            const target = document.querySelector(tab.dataset.dotNav);
            dotNavs.forEach((tab) => {
                tab.classList.remove("active");
            });
            tabContents.forEach((tabContent) => {
                tabContent.classList.remove("active");
            });
            tabs.forEach((dot) => {
                dot.classList.remove("active");
                if (dot.dataset.tabBtn === tab.dataset.dotNav) {
                    dot.classList.add("active");
                }
            });
            tab.classList.add("active");
            target.classList.add("active");
        });
    });
})();
