$(document).ready(function () {
    if (window.matchMedia('(min-width: 992px)').matches) {
        let element_left = document.getElementsByClassName("SiteEnter--hero-left");
        let element_right = document.getElementsByClassName("SiteEnter--hero-right");

        setTimeout(function () {
            $('.Section--hero').addClass('SiteEnter--animation');
        },200);

        if (element_left.length > 0 && element_right.length > 0) {
            let SiteEnterContent = $('.SiteEnter__content');
            let height = SiteEnterContent.innerHeight();

            SiteEnterContent.css('height',height - 60);
            // Play video when mouseover. Pause video when mouseout.
            ['.SiteEnter--hero-left', '.SiteEnter--hero-right'].map(selector => {
                const element = document.querySelector(selector);
                element.addEventListener('mouseover', () => element.querySelector('video').play());
                element.addEventListener('mouseout', () => element.querySelector('video').pause());
            });
        }

        let videocard_left = document.getElementsByClassName("VideoCard--left");
        let videocard_right = document.getElementsByClassName("VideoCard--right");

        if (videocard_left.length > 0 && videocard_right.length > 0) {
            let SiteEnterContent = $('.VideoCard');
            let height = SiteEnterContent.innerHeight();

            SiteEnterContent.css('height',height);
            // Play video when mouseover. Pause video when mouseout.
            ['.VideoCard--left', '.VideoCard--right'].map(selector => {
                const element = document.querySelector(selector);
                element.addEventListener('mouseover', () => element.querySelector('video').play());
                element.addEventListener('mouseout', () => element.querySelector('video').pause());
            });
        }
    }

});
