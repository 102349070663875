document.addEventListener("DOMContentLoaded", () => {
    jQuery('.class1').hover(function() {
        jQuery('.class1').addClass('active');
    }, function() {
        jQuery('.class1').removeClass('active');
    })

    $(window).resize(function() {
        let items = $(".desktop-pricing-table-cards .item").not(
            '.header-item, .footer-item, .with-button');
        resizeGeneralItems(items);

        let text = $(".desktop-pricing-table-cards .description-wrapper");
        resizeGeneralItems(text);

        let buttons = $(".desktop-pricing-table-cards a.btn");
        resizeGeneralItems(buttons);

        let headerItems = $(
            ".desktop-pricing-table-cards .item.header-item, .desktop-pricing-table-cards .header"
        );
        resizeGeneralItems(headerItems);
    });

    function resizeGeneralItems(items) {
        items.height('auto')
        var maxHeight = Math.max.apply(null, items.map(function() {
            return $(this).height();
        }).get());
        items.height(maxHeight);
    }
});